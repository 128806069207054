<template>
  <div class="content">
    <div class="skv-questions__row">
      <skv-questions @repeat="repeat" :key="qKey" :savedActiveTab="activeTab"/>
      <div class="sticky">
        <RightAside
          v-if="asideItems && asideItems.length"
          :items="asideItems"
        />
      </div>
    </div>
  </div>
</template>

<script>
import skvQuestions from "../../components/interactiveTests/skv-questions.vue";
import RightAside from "../../components/pageComponents/RightAside.vue";
export default {
  components: { skvQuestions, RightAside },
  name: "SKVQuestions",
  data: () => ({
    qKey: 0,
    activeTab: null
  }),
  computed: {
    asideItems() {
      return [
        {
          title: "Препараты АЗ",
          // descr: this.TextDetail.nosology.map((el) => el.title).join(","),
          image: require("@/assets/img/cardio-img/aside-1.jpg"),
          image_desktop: require("@/assets/img/cardio-img/aside-1_small.jpg"),
          theme: "white",
          event: "medicationsClick",
          to: {
            name: "Medications",
          },
        },
        {
          title: "Календарь мероприятий",
          image: require("@/assets/img/cardio-img/aside-2.jpg"),
          image_desktop: require("@/assets/img/cardio-img/aside-2_small.jpg"),
          event: "calendarClick",
          to: {
            name: "Events",
          },
        },
        {
          title: "Бибилиотека",
          image: require("@/assets/img/cardio-img/aside-3.jpg"),
          image_desktop: require("@/assets/img/cardio-img/aside-3_small.jpg"),
          links: [
            {
              event: "libClick",
              title: "Публикации",
              to: {
                name: "TextMaterials",
              },
            },
            {
              event: "libClick",
              title: "Видеоматериалы",
              to: {
                name: "VideoMaterials",
              },
            },
            {
              event: "libClick",
              title: "Подкасты",
              to: {
                name: "Podcasts",
              },
            },
          ],
          theme: "dark",
        },
      ];
    },
  },
  methods: {
    repeat(activeTab) {
      this.activeTab = activeTab
      this.qKey++
    }
  }
};
</script>

<style lang="scss" scoped>
.skv-questions {
  &__row {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 1fr 280px;
    column-gap: 136px;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }
}

.sticky {
  @media screen and (min-width: 1221px) {
    position: sticky;
    top: 115px;
  }
}
</style>